@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026                          ;
  @apply text-white;
}    

@layer components {
  .inactive {
      width: 0px !important;
      opacity: 75%;
  }

  
.pagination {
  display: flex;
  justify-content: end;
  list-style: none;
  cursor: pointer;
  padding: 20px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #DC4492;
}


.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
}